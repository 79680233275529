<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1 class="custom-header-title">
                  Editing {{ booking.stringID }} - {{ booking.customerName }}, {{ dateFormatWithTime(booking.start) }}
                  <b-badge
                    :variant="booking.activityType ? booking.activityType.color : ''"
                    :style="{ '--bg-color': booking.activityType ? booking.activityType.color : '' }"
                    class="small-badge-button small-badge-button-activity-list"
                  >
                    <span v-if="booking.eventID">{{ booking.eventID ? booking.eventID.name : '' }}</span>
                    <span v-else>{{ booking.activityType ? booking.activityType.typeName : '' }}</span>
                  </b-badge>
                </h1>
                <p class="log_info">
                  Created by {{ booking.createdBy ? booking.createdBy.name : '' }} on {{ dateFormatWithTime(booking.createdAt) }} <br>Last updated on {{ dateFormatWithTime(booking.updatedAt) }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="bookingEditForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Customer Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-8">
              <b-form-group
                label="Customer Name*"
                label-for="h-booking-customerName"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer Name"
                  vid="customerName"
                  rules="required"
                >
                  <!-- <b-form-input
                    v-if="!searchCustomer"
                    id="h-booking-customerName"
                    v-model="customerName"
                    placeholder="First name and last name"
                    :state="(errors.length > 0 || customerNameValidation) ? false : null"
                    name="customerName"
                    @input="customerNameValidation == true ? customerNameValidation = false : null"
                  /> -->
                  <b-input-group>
                    <b-form-input
                      id="search_name"
                      v-model="query"
                      label="Applicant Name"
                      name="name"
                      placeholder="Search Customer Name, NRIC, Mobile No. or Email"
                      autocomplete="off"
                      tabindex="0"
                      :class="!showCustomerCrossIcon ? 'input__append-border-right' : ''"
                      :disabled="showCustomerCrossIcon"
                      @keyup="fetchResults()"
                      @focusout="resetCustomerSearchResult"
                    />
                    <b-input-group-append
                      v-if="showCustomerCrossIcon"
                      is-text
                    >
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer text-primary"
                        size="18"
                        data-toggle
                        @click="clearCustomerSearchInput"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-show="showCustomerSearchResult"
                    class="search-results mt-50 z-index-99"
                  >
                    <div v-if="foundCustomers.length">
                      <b-list-group flush>
                        <b-list-group-item>
                          <h3 class="mb-0 result-search-header">
                            Customers found
                          </h3>
                        </b-list-group-item>
                        <b-list-group-item
                          v-for="(customer, cIndex) in foundCustomers"
                          :key="cIndex"
                          class="cursor-pointer"
                          @click="setCustomer(customer)"
                        >
                          <h6 class="mb-0 fs-14 text-primary">
                            {{ customer.name }}
                          </h6>
                          <p class="mb-0 fs-12">
                            {{ customer.perContact }} / {{ customer.encryptedNric }} {{ customer.perEmail ? `/ ${customer.perEmail}` : '' }}
                          </p>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                  <div
                    v-show="searchCustomerResultNotFound"
                    class="search-results mt-50 z-index-99"
                  >
                    <b-list-group>
                      <b-list-group-item>
                        <h3 class="mb-0 result-search-header">
                          No result found.
                        </h3>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerNameValidation"
                    class="text-danger"
                  >
                    {{ customerNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-4">
              <b-button
                v-if="searchCustomer"
                variant="link"
                @click="unlinkCustomer()"
              >
                Unlink customer
              </b-button>
              <b-button
                v-if="showCustomerLinkButton"
                variant="link"
                @click="linkCustomer()"
              >
                Link to a customer
              </b-button>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Mobile Number*"
                label-for="h-booking-customerContact"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile Number"
                  vid="customerContact"
                  :rules="customerEmail === '' ? 'required' : ''"
                >
                  <b-form-input
                    id="h-booking-customerContact"
                    v-model="customerContact"
                    placeholder="+65 Enter 8-digits number"
                    :state="(errors.length > 0 || customerContactValidation) ? false : null"
                    name="customerContact"
                    :disabled="showCustomerCrossIcon"
                    @input="checkForCustomerSchedule"
                  />
                  <!-- <cleave
                    id="h-booking-customerContact"
                    v-model="customerContact"
                    class="form-control"
                    :raw="false"
                    :options="options.prefix"
                    :disabled="showCustomerCrossIcon"
                    @input="validateCustomerContact"
                  /> -->
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <small
                    v-if="customerContactValidation"
                    class="text-danger"
                  >
                    {{ customerContactError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Email"
                label-for="h-booking-customerEmail"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{}"
                  name="Email"
                  vid="customerEmail"
                  data-vv-validate-on="none"
                  :rules="!customerContact.length ? 'required|email' : 'email'"
                >
                  <b-form-input
                    id="h-booking-customerEmail"
                    v-model="customerEmail"
                    placeholder="Email"
                    :state="(customerEmailValidation) ? false : null"
                    :disabled="showCustomerCrossIcon"
                    name="customerEmail"
                    @input="checkForCustomerSchedule"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <small
                    v-if="customerEmailValidation"
                    class="text-danger"
                  >
                    {{ customerEmailError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Booking Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Activity Type"
                vid="activityType"
                rules="required"
              >
                <b-form-group
                  label="Activity Type*"
                  label-for="h-bookings-activityType"
                  label-cols-md="4"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    id="h-bookings-activityType"
                    v-model="activityType"
                    label="typeName"
                    :options="activityTypeOptions"
                    :reduce="typeName => typeName._id"
                    :clearable="false"
                    disabled
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col
              v-if="showEventSelection"
              cols="md-6"
            >
              <validation-provider
                #default="{ errors }"
                name="Event"
                vid="eventID"
                rules="required"
              >
                <b-form-group
                  label="Event*"
                  label-for="h-bookings-eventID"
                  label-cols-md="4"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    id="h-bookings-eventID"
                    v-model="eventID"
                    label="name"
                    placeholder="Select an Event"
                    :options="eventOptions"
                    :reduce="name => name._id"
                    :clearable="false"
                    disabled
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="showEventSelection"
              cols="md-6"
            />

            <b-col
              v-if="showTimeSelection"
              cols="md-6"
            >
              <b-form-group
                label="Start Date*"
                label-for="h-event-start"
                label-cols-md="4"
              >
                <b-input-group>
                  <flat-pickr
                    id="h-event-start"
                    v-model="start"
                    class="form-control flat-pickr-group"
                    :config="flatPickrConfig"
                    @input="startValidation == true ? startValidation = false : null"
                    @on-change="getTimeFromDB"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="startValidation"
                  class="text-danger"
                >
                  {{ startError }}
                </small>
              </b-form-group>
            </b-col>
            <b-col
              v-if="showTimeSelection"
              cols="md-6"
            />

            <b-col
              v-if="showTimeSelection"
              cols="12"
            >
              <b-form-group
                label="Start Time*"
                label-for="startTime"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Start Time"
                  rules="required"
                >
                  <b-form-radio-group
                    id="startTime"
                    v-model="startTime"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, index) in hoursInterval"
                      :key="index"
                      :value="option.timeValue"
                      class="mb-50"
                      :disabled="option.status !== 'enabled'"
                      :state="(errors.length > 0) ? false : null"
                      @change="checkForCustomerSchedule"
                    >
                      <feather-icon
                        v-if="startTime === option.timeValue"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      {{ option.time }} <span class="bookingCounter">{{ option.count }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <br>

                  <small
                    v-if="startTimeValidation"
                    class="text-danger"
                  >
                    {{ startTimeError }}
                  </small>
                  <small
                    v-if="scheduleValidation"
                    class="text-danger"
                  >
                    {{ scheduleError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Source of Booking"
                vid="sourceOfBooking"
                rules="required"
              >
                <b-form-group
                  label="Source of Booking*"
                  label-for="h-bookings-sourceOfBooking"
                  label-cols-md="4"
                  :state="(errors.length > 0 || sourceOfBookingValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-sourceOfBooking"
                    v-model="sourceOfBooking"
                    label="title"
                    :options="sourceOfBookingOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="sourceOfBookingValidation == true ? sourceOfBookingValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="sourceOfBookingValidation"
                    class="text-danger"
                  >
                    {{ sourceOfBookingError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="h-booking-description"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  vid="description"
                  rules=""
                >
                  <b-form-textarea
                    id="event-description"
                    v-model="description"
                    placeholder="Description"
                    rows="3"
                    name="description"
                    @input="descriptionValidation == true ? descriptionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="descriptionValidation"
                    class="text-danger"
                  >
                    {{ descriptionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Remarks"
                label-for="h-booking-remarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-textarea
                    id="event-remarks"
                    v-model="remarks"
                    placeholder="Remarks"
                    rows="3"
                    name="remarks"
                    @input="remarksValidation == true ? remarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Title*"
                label-for="h-booking-title"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  vid="title"
                  rules="required"
                >
                  <b-form-input
                    id="h-booking-title"
                    v-model="title"
                    placeholder="Booking Title"
                    :state="(errors.length > 0 || titleValidation) ? false : null"
                    name="title"
                    @input="titleValidation == true ? titleValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="titleValidation"
                    class="text-danger"
                  >
                    {{ titleError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Duty Officer"
                vid="dutyOfficer"
                rules="required"
              >
                <b-form-group
                  label="Duty Officer*"
                  label-for="h-bookings-dutyOfficer"
                  label-cols-md="4"
                  :state="(errors.length > 0 || dutyOfficerValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-dutyOfficer"
                    v-model="dutyOfficer"
                    label="name"
                    placeholder="Select an option"
                    :options="dutyOfficerOptions"
                    :reduce="name => name._id"
                    :clearable="false"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dutyOfficerValidation"
                    class="text-danger"
                  >
                    {{ dutyOfficerError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Payment Details
            </h3>
          </template>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Payment Remarks"
                label-for="h-booking-paymentRemarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Payment Remarks"
                  vid="paymentRemarks"
                  rules=""
                >
                  <b-form-textarea
                    id="event-paymentRemarks"
                    v-model="paymentRemarks"
                    placeholder="Payment Remarks"
                    rows="3"
                    name="paymentRemarks"
                    @input="paymentRemarksValidation == true ? paymentRemarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="paymentRemarksValidation"
                    class="text-danger"
                  >
                    {{ paymentRemarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Agent Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-8">
              <b-form-group
                label="Agent Name"
                label-for="h-booking-agentName"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agent Name"
                  vid="agentName"
                  rules=""
                >
                  <!-- <b-form-input
                    v-if="!searchAgent"
                    id="h-booking-agentName"
                    v-model="agentName"
                    placeholder="Agent Name"
                    :state="(errors.length > 0 || agentNameValidation) ? false : null"
                    name="agentName"
                    @input="agentNameValidation == true ? agentNameValidation = false : null"
                  /> -->
                  <b-input-group>
                    <b-form-input
                      id="search_agent_name"
                      v-model="agentQuery"
                      label="Agent Name"
                      name="name"
                      placeholder="Search Agent Name or Code"
                      autocomplete="off"
                      tabindex="0"
                      :class="!showAgentCrossIcon ? 'input__append-border-right' : ''"
                      :disabled="showAgentCrossIcon"
                      @keyup="fetchAgentResults()"
                      @focusout="resetAgentSearchResult"
                    />
                    <b-input-group-append
                      v-if="showAgentCrossIcon"
                      is-text
                    >
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer text-primary"
                        size="18"
                        data-toggle
                        @click="clearAgentSearchInput"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-show="showAgentSearchResult"
                    class="search-results mt-50 z-index-99"
                  >
                    <div v-if="foundAgents.length">
                      <b-list-group flush>
                        <b-list-group-item>
                          <h3 class="mb-0 result-search-header">
                            Agents found
                          </h3>
                        </b-list-group-item>
                        <b-list-group-item
                          v-for="(agent, sIndex) in foundAgents"
                          :key="sIndex"
                          class="cursor-pointer"
                          @click="setAgent(agent)"
                        >
                          <h6 class="mb-0 fs-14 text-primary">
                            {{ agent.name }}
                          </h6>
                          <p class="mb-0 fs-12">
                            {{ agent.code }}
                          </p>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                  <div
                    v-show="searchAgentResultNotFound"
                    class="search-results mt-50 z-index-99"
                  >
                    <b-list-group>
                      <b-list-group-item>
                        <h3 class="mb-0 result-search-header">
                          No result found.
                        </h3>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentNameValidation"
                    class="text-danger"
                  >
                    {{ agentNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-4">
              <b-button
                v-if="searchAgent"
                variant="link"
                @click="unlinkAgent()"
              >
                Unlink agent
              </b-button>
              <b-button
                v-if="showAgentLinkButton"
                variant="link"
                @click="linkAgent()"
              >
                Link to an agent
              </b-button>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Agent Code"
                label-for="h-booking-agentCode"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Agent Code"
                  vid="agentCode"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-agentCode"
                    v-model="agentCode"
                    placeholder="Agent Code"
                    :state="(errors.length > 0 || agentCodeValidation) ? false : null"
                    :disabled="showAgentCrossIcon"
                    name="agentCode"
                    @input="agentCodeValidation == true ? agentCodeValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentCodeValidation"
                    class="text-danger"
                  >
                    {{ agentCodeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Mobile Number"
                label-for="h-booking-agentContact"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile Number"
                  vid="agentContact"
                  rules=""
                >
                  <b-form-input
                    id="h-booking-agentContact"
                    v-model="agentContact"
                    placeholder="+65 Enter 8-digits number"
                    :state="(errors.length > 0 || agentContactValidation) ? false : null"
                    :disabled="showAgentCrossIcon"
                    name="agentContact"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="agentContactValidation"
                    class="text-danger"
                  >
                    {{ agentContactError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>

        <b-card
          v-if="activitySelection == 'event' && eventPackageOptions.length"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Event Packages
            </h3>
          </template>

          <b-row>
            <b-col
              cols="md-6"
            >
              <validation-provider
                #default="{ errors }"
                name="Event Package"
                vid="packageID"
                rules="required"
              >
                <b-form-group
                  label="Package*"
                  label-for="h-bookings-packageID"
                  label-cols-md="4"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    id="h-bookings-packageID"
                    v-model="packageID"
                    label="name"
                    placeholder="Select a Package"
                    :options="eventPackageOptions"
                    :reduce="name => name._id"
                    :clearable="false"
                    @input="setEventPackageCustomFields"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="md-6"
            />
          </b-row>
          <div v-if="packageID && packageID != ''">
            <b-row
              v-for="(opt, key) in activityCustomFields"
              :key="key"
            >
              <b-col cols="12">

                <b-form-group
                  v-if="opt.type == 'short-answer'"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  label-cols-md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :vid="'event-custom-field-' + key"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-input
                      :id="'event-custom-field-' + key"
                      v-model="customFields[key].value"
                      :placeholder="opt.label"
                      :state="(errors.length > 0) ? false : null"
                      @input="generateBookingTitle"
                    />
                    <small class="description-display-block">{{ opt.description }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'long-answer'"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  label-cols-md="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="opt.label"
                    :vid="'event-custom-field-' + key"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-textarea
                      :id="'event-custom-field-' + key"
                      v-model="customFields[key].value"
                      trim
                      :placeholder="opt.label"
                      :state="(errors.length > 0) ? false : null"
                      @input="generateBookingTitle"
                    />
                    <small class="description-display-block">{{ opt.description }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'multi-select' && opt.multiSelectType == 'multiple'"
                  label-cols-md="2"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >

                    <b-form-checkbox-group
                      :id="'event-custom-field-' + key"
                      v-model="customFields[key].value"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio"
                      @input="generateBookingTitle"
                    >
                      <b-form-checkbox
                        v-for="(option, kindex) in opt.extraOptions"
                        :key="kindex"
                        :value="option.key"
                        class="mb-50 customCheckbox"
                      >
                        <feather-icon
                          v-if="customFields[key].value.includes(option.key)"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        <span class="align-middle">{{ option.key }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <small class="description-display-block">{{ opt.description }}</small>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'multi-select' && opt.multiSelectType == 'single'"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                  label-cols-md="2"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-checkbox-group
                      :id="'event-custom-field-' + key"
                      v-model="customFields[key].value"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio display-block-checkbox"
                      @input="makeCheckboxToRadio(key)"
                    >
                      <b-form-checkbox
                        v-for="(option, kindex) in opt.extraOptions"
                        :key="kindex"
                        :value="option.key"
                        class="mb-50 customCheckbox"
                      >
                        <feather-icon
                          v-if="customFields[key].value.includes(option.key)"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        <span class="align-middle">{{ option.key }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <small class="description-display-block">{{ opt.description }}</small>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="opt.type == 'single-select'"
                  label-cols-md="2"
                  :label="opt.label"
                  :label-for="'event-custom-field-' + key"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-checkbox-group
                      :id="'event-custom-field-' + key"
                      v-model="customFields[key].value"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio display-block-checkbox"
                      @input="makeCheckboxToRadio(key)"
                    >
                      <b-form-checkbox
                        v-for="(option, kindex) in requiredOptions"
                        :key="kindex"
                        :value="option.value"
                        class="mb-50 customCheckbox"
                      >
                        <feather-icon
                          v-if="customFields[key].value.includes(option.value)"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        <span class="align-middle">{{ option.text }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <small class="description-display-block">{{ opt.description }}</small>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <b-card
          v-if="activityCustomFields.length > 0 && activitySelection == 'activity'"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Custom Form
            </h3>
          </template>
          <b-row
            v-for="(opt, key) in activityCustomFields"
            :key="key"
          >
            <b-col cols="12">

              <b-form-group
                v-if="opt.type == 'short-answer'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-input
                    :id="'event-custom-field-' + key"
                    v-model="customFields[key].value"
                    :placeholder="opt.label"
                    :state="(errors.length > 0) ? false : null"
                    @input="generateBookingTitle"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'long-answer'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-textarea
                    :id="'event-custom-field-' + key"
                    v-model="customFields[key].value"
                    trim
                    :placeholder="opt.label"
                    :state="(errors.length > 0) ? false : null"
                    @input="generateBookingTitle"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'multi-select' && opt.multiSelectType == 'multiple'"
                label-cols-md="2"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
              >
                <validation-provider
                  #default="validationContext"
                  :name="opt.label"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >

                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="customFields[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio"
                    @input="generateBookingTitle"
                  >
                    <b-form-checkbox
                      v-for="(option, kindex) in opt.extraOptions"
                      :key="kindex"
                      :value="option.key"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="customFields[key].value.includes(option.key)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.key }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'multi-select' && opt.multiSelectType == 'single'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="2"
              >
                <validation-provider
                  #default="validationContext"
                  :name="opt.label"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >
                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="customFields[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio display-block-checkbox"
                    @input="makeCheckboxToRadio(key)"
                  >
                    <b-form-checkbox
                      v-for="(option, kindex) in opt.extraOptions"
                      :key="kindex"
                      :value="option.key"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="customFields[key].value.includes(option.key)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.key }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'single-select'"
                label-cols-md="2"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
              >
                <validation-provider
                  #default="validationContext"
                  :name="opt.label"
                  :rules="opt.required == 'yes' ? 'required' : ''"
                >

                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="customFields[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio display-block-checkbox"
                    @input="makeCheckboxToRadio(key)"
                  >
                    <b-form-checkbox
                      v-for="(option, kindex) in requiredOptions"
                      :key="kindex"
                      :value="option.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="customFields[key].value.includes(option.value)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Call Logs
            </h3>
          </template>
          <b-row v-if="showCallLogFields">
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Type of Call"
                vid="typeOfCall"
                rules=""
              >
                <b-form-group
                  label="Type of Call*"
                  label-for="h-bookings-typeOfCall"
                  label-cols-md="4"
                  :state="(errors.length > 0 || typeOfCallValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-typeOfCall"
                    v-model="typeOfCall"
                    label="name"
                    placeholder="Select an option"
                    :options="callLogTypes"
                    :reduce="name => name._id"
                    :clearable="false"
                    @input="setNatureOfCalls"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeOfCallValidation"
                    class="text-danger"
                  >
                    {{ typeOfCallError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Nature of Call"
                vid="natureOfCall"
                rules=""
              >
                <b-form-group
                  label="Nature of Call*"
                  label-for="h-bookings-natureOfCall"
                  label-cols-md="4"
                  :state="(errors.length > 0 || natureOfCallValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-natureOfCall"
                    v-model="natureOfCall"
                    label="title"
                    placeholder="Nature of this call"
                    :options="callNatures"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="natureOfCallValidation == true ? natureOfCallValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="natureOfCallValidation"
                    class="text-danger"
                  >
                    {{ natureOfCallError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="12">
              <b-form-group
                label="Details and Remarks"
                label-for="h-booking-callRemarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Details and Remarks"
                  vid="callRemarks"
                  rules=""
                >
                  <b-form-textarea
                    id="event-callRemarks"
                    v-model="callRemarks"
                    placeholder="Details and Remarks"
                    rows="3"
                    name="callRemarks"
                    @input="callRemarksValidation == true ? callRemarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="callRemarksValidation"
                    class="text-danger"
                  >
                    {{ callRemarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="showCallLogFields = true"
                >
                  <span class="align-middle">Add a call log</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="cancelEdit"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BBadge, BInputGroupAppend,
  BFormCheckboxGroup, BFormCheckbox, BFormRadioGroup, BFormRadio, BFormInvalidFeedback, BInputGroup, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
//  import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BFormInvalidFeedback,
    BListGroup,
    BListGroupItem,
    // Cleave,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showAgentCrossIcon: false,
      showAgentSearchResult: false,
      searchAgentResultNotFound: false,
      foundAgents: [],
      showCustomerCrossIcon: false,
      showCustomerSearchResult: false,
      searchCustomerResultNotFound: false,
      foundCustomers: [],
      booking: {},
      customerID: '',
      agentID: '',
      customerName: '',
      customerContact: '+65 ',
      customerEmail: '',
      start: '',
      startTime: '',
      sourceOfBooking: 'call',
      description: '',
      remarks: '',
      paymentRemarks: '',
      activityType: '',
      eventID: '',
      packageID: '',
      agentName: '',
      agentCode: '',
      agentContact: '+65 ',
      typeOfCall: '',
      natureOfCall: '',
      callRemarks: '',
      dutyOfficer: '',
      hoursInterval: [],
      timeSlotDuration: '',
      scheduleError: '',
      title: '',
      titleError: 'Valid title is required',
      titleValidation: false,
      activitySelection: 'activity',
      scheduleValidation: false,
      customerNameError: 'Valid name is required',
      customerNameValidation: false,
      customerContactError: 'Valid customer contact is required',
      customerContactValidation: false,
      agentContactError: 'Valid agent contact is required',
      agentContactValidation: false,
      customerEmailError: 'Valid email is required',
      customerEmailValidation: false,
      startError: 'Valid value is required',
      startValidation: false,
      startTimeError: 'Valid value is required',
      startTimeValidation: false,
      sourceOfBookingError: 'Valid value is required',
      sourceOfBookingValidation: false,
      descriptionError: 'Valid value is required',
      descriptionValidation: false,
      remarksError: 'Valid value is required',
      remarksValidation: false,
      paymentRemarksError: 'Valid value is required',
      paymentRemarksValidation: false,
      agentNameError: 'Valid value is required',
      agentNameValidation: false,
      agentCodeError: 'Valid value is required',
      agentCodeValidation: false,
      typeOfCallError: 'Valid value is required',
      typeOfCallValidation: false,
      natureOfCallError: 'Valid value is required',
      natureOfCallValidation: false,
      callRemarksError: 'Valid value is required',
      callRemarksValidation: false,
      eventIDError: 'Valid value is required',
      eventIDValidation: false,
      packageIDError: 'Valid value is required',
      dutyOfficerError: 'Valid value is required',
      dutyOfficerValidation: false,
      packageIDValidation: false,
      showEventSelection: false,
      showCallLogFields: false,
      showTimeSelection: true,
      activityTypeOptions: [],
      eventOptions: [],
      eventPackageOptions: [],
      dutyOfficerOptions: [],
      callLogTypes: [],
      callNatures: [],
      customFields: [],
      previousValues: [],
      activityCustomFields: [],
      statusOptions: [
        { title: 'Confirmed', code: 'confirmed' },
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Payment', code: 'pending-payment' },
        { title: 'Pending Approval', code: 'pending-approval' },
        { title: 'Waiting List', code: 'waiting-list' },
      ],
      sourceOfBookingOptions: [
        { title: 'Call', code: 'call' },
        { title: 'Message', code: 'message' },
        { title: 'Walk-in', code: 'walk-in' },
        { title: 'Self-service', code: 'self-service' },
      ],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      options: {
        prefix: {
          numericOnly: true,
          prefix: '+65',
          blocks: [3, 8],
        },
      },
      searchCustomer: false,
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 1000,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Search Customer Name, NRIC, Mobile No. or Email',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {},
      searchAgent: false,
      agentQuery: '',
      agentResults: [],
      agentInputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Search Agent Name or Code',
        class: 'form-control',
        name: 'agentAjax',
      },
      agentSuggestions: [],
      showAgentLinkButton: false,
      showCustomerLinkButton: false,

      // validation rules
      required,
    }
  },

  created() {
    this.$http.get(`appointment/bookings/${this.$route.params.id}`)
      .then(response => {
        this.booking = response.data
        this.customerID = response.data.customer || ''
        this.agentID = response.data.agent || ''
        // if (this.customerID) {
        //   this.query = response.data.customerName || ''
        //   this.searchCustomer = true
        // }
        if (this.customerID) {
          this.query = response.data.customerName || ''
          this.searchCustomer = true
        } else {
          this.showCustomerLinkButton = true
          this.query = response.data.customerName || ''
        }
        if (this.agentID) {
          this.agentQuery = response.data.agentName || ''
          this.searchAgent = true
        } else {
          this.showAgentLinkButton = true
          this.agentQuery = response.data.agentName || ''
        }
        this.customerName = response.data.customerName || ''
        const cContact = response.data.customerContact || '+65 '
        this.customerEmail = response.data.customerEmail || ''
        this.start = this.dateFormat(response.data.start)
        this.startTime = this.twentyFourHourTimeFormatFromDateString(response.data.start)
        this.sourceOfBooking = response.data.sourceOfBooking || ''
        this.description = response.data.description || ''
        this.remarks = response.data.remarks || ''
        this.title = response.data.title || ''
        this.dutyOfficer = response.data.dutyOfficer || ''
        this.paymentRemarks = response.data.paymentRemarks || ''

        this.activityType = response.data.activityType._id || ''

        this.eventID = response.data.eventID ? response.data.eventID._id : ''

        this.packageID = response.data.packageID || ''
        this.agentName = response.data.agentName || ''
        this.agentCode = response.data.agentCode || ''
        this.agentContact = response.data.agentContact || '+65 '
        this.previousValues = response.data.customFields || []
        // const fullData = response.data.customFields.map(field => {
        //   const extraOptionsData = field.extraOptions.map(option => {
        //     const optionData = {
        //       key: option.key,
        //       keyInvalid: false,
        //       keyInvalidMessage: '',
        //       value: option.value,
        //       valueInvalid: false,
        //       valueInvalidMessage: '',
        //     }
        //     return optionData
        //   })

        //   const properties = {
        //     type: field.type,
        //     label: field.label,
        //     file: null,
        //     base64File: field.file,
        //     description: field.description,
        //     required: field.required,
        //     displayInCalendar: field.displayInCalendar,
        //     extraOptions: extraOptionsData,
        //     multiSelectType: field.multiSelectType,
        //     typeInvalid: false,
        //     typeInvalidMessage: '',
        //     labelInvalid: false,
        //     labelInvalidMessage: '',
        //     requiredInvalid: false,
        //     requiredInvalidMessage: '',
        //     displayInCalendarInvalid: false,
        //     displayInCalendarInvalidMessage: '',
        //     multiSelectTypeInvalid: false,
        //     multiSelectTypeInvalidMessage: '',
        //   }
        //   return properties
        // })

        // this.customFields = fullData || []
        // this.customerContact = this.modifyContact(cContact)
        this.customerContact = cContact
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get('appointment/bookings/respond-with/duty-officers')
      .then(response => {
        this.dutyOfficerOptions = response.data.dutyOfficers || []
        this.callLogTypes = response.data.callLogTypes || []
        this.activityTypeOptions = response.data.activityTypeOptions || []
        this.eventOptions = response.data.eventOptions || []

        if (this.eventID) {
          this.setEventPackages()
          this.activitySelection = 'event'
          this.showEventSelection = true
        } else {
          const type = this.activityTypeOptions.find(o => o._id === this.activityType)
          if (type) {
            if (type.customFields.length) {
              const newArray = []
              type.customFields.forEach(arrayItem => {
                const newObj = {}
                newObj.key = arrayItem.label
                const exist = this.previousValues.find(o => o.key === arrayItem.label)
                if (exist) {
                  if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                    if (Array.isArray(exist.value)) {
                      newObj.value = exist.value || []
                    } else {
                      newObj.value = [exist.value] || []
                    }
                  } else {
                    newObj.value = exist.value || ''
                  }
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                    newObj.value = []
                  } else {
                    newObj.value = ''
                  }
                }
                newArray.push(newObj)
              })

              this.customFields = newArray
            }

            this.activityCustomFields = type.customFields || []
          }
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    linkAgent() {
      this.showAgentLinkButton = false
      this.showAgentCrossIcon = false
      this.agentQuery = ''
      this.agentID = ''
      this.agentName = ''
      this.agentCode = ''
      this.agentContact = '+65 '
    },
    unlinkAgent() {
      this.searchAgent = !this.searchAgent
      this.showAgentCrossIcon = false
      this.agentQuery = ''
      this.agentID = ''
      this.agentName = ''
      this.agentCode = ''
      this.agentContact = '+65 '
    },
    linkCustomer() {
      this.showCustomerLinkButton = false
      this.showCustomerCrossIcon = false
      this.query = ''
      this.customerID = ''
      this.customerName = ''
      this.customerContact = '+65 '
      this.customerEmail = ''
    },
    unlinkCustomer() {
      this.searchCustomer = !this.searchCustomer
      this.showCustomerCrossIcon = false
      this.query = ''
      this.customerID = ''
      this.customerName = ''
      this.customerContact = '+65 '
      this.customerEmail = ''
    },
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      if (query.length > 2) {
        this.timeout = setTimeout(() => {
          this.$http.get('appointment/bookings/respond-with/customers', { params: { search: query } })
            .then(response => {
              if (response.data.customers.length === 0) {
                this.foundCustomers = []
                this.searchCustomerResultNotFound = true
                this.showCustomerSearchResult = false
              } else {
                this.foundCustomers = response.data.customers
                this.showCustomerSearchResult = true
                this.searchCustomerResultNotFound = false
              }
              this.customerName = ''
              this.customerContact = '+65 '
              this.customerEmail = ''
              this.customerID = ''
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }, this.debounceMilliseconds)
      }
    },
    setCustomer(customer) {
      this.showCustomerCrossIcon = true
      this.query = customer.name
      this.customerName = customer.name
      this.customerContact = customer.perContact || '+65 '
      this.customerEmail = customer.perEmail || ''
      this.customerID = customer._id
    },
    resetCustomerSearchResult() {
      setTimeout(() => {
        this.searchCustomerResultNotFound = false
        this.showCustomerSearchResult = false
      }, 500)
    },
    clearCustomerSearchInput() {
      this.query = ''
      this.customerName = ''
      this.customerContact = '+65 '
      this.customerEmail = ''
      this.customerID = ''
      this.showCustomerCrossIcon = false
      this.searchCustomerResultNotFound = false
      this.showCustomerSearchResult = false
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },
    fetchAgentResults() {
      const { agentQuery } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$http.get('appointment/bookings/respond-with/agents', { params: { search: agentQuery } })
          .then(response => {
            if (response.data.agents.length === 0) {
              this.foundAgents = []
              this.showAgentSearchResult = false
              this.searchAgentResultNotFound = true
            } else {
              this.foundAgents = response.data.agents
              this.showAgentSearchResult = true
              this.searchAgentResultNotFound = false
            }
            this.agentName = ''
            this.agentContact = '+65 '
            this.agentEmail = ''
            this.agentID = ''
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }, this.debounceMilliseconds)
    },
    resetAgentSearchResult() {
      setTimeout(() => {
        this.searchAgentResultNotFound = false
        this.showAgentSearchResult = false
      }, 500)
    },
    clearAgentSearchInput() {
      this.agentQuery = ''
      this.agentName = ''
      this.agentContact = '+65 '
      this.agentCode = ''
      this.agentID = ''
      this.showAgentCrossIcon = false
      this.searchAgentResultNotFound = false
      this.showAgentSearchResult = false
    },
    setAgent(item) {
      this.showAgentCrossIcon = true
      this.agentQuery = item.name
      this.agentName = item.name
      this.agentContact = item.personalContact || '+65 '
      this.agentCode = item.code
      this.agentID = item._id
    },
    getSuggestionValueAgent(suggestion) {
      const { item } = suggestion
      return item.name
    },
    getTimeFromDB() {
      if (this.start && this.activityType && this.eventID === '') {
        this.$http
          .post('appointment/bookings/date-and-time-availability', { date: this.start, activity: this.activityType })
          .then(response => {
            this.hoursInterval = response.data.hours
            this.timeSlotDuration = response.data.timeSlotDuration
            this.startValidation = false
          })
          .catch(error => {
            this.hoursInterval = []
            this.timeSlotDuration = ''
            this.startValidation = true
            this.startError = error.data.message
          })
      } else if (this.start && this.activityType && this.eventID) {
        this.$http
          .post('appointment/bookings/date-and-time-availability/event', { date: this.start, event: this.eventID })
          .then(response => {
            if (response.data.startTime) {
              this.startTime = response.data.startTime
            } else {
              this.hoursInterval = response.data.hours
            }
            this.timeSlotDuration = response.data.timeSlotDuration
            this.startValidation = false
          })
          .catch(error => {
            this.hoursInterval = []
            this.timeSlotDuration = ''
            this.startValidation = true
            this.startError = error.data.message
          })
      }
    },
    validateCustomerContact() {
      if (this.customerContact.length > 4 && this.customerContact.length <= 12) {
        this.customerContact = this.modifyContact(this.customerContact)

        if (this.customerContact.length === 12) {
          this.customerContactValidation = false
          this.checkForCustomerSchedule()
        } else {
          this.customerContactValidation = true
        }
      } else if (this.customerContact.length === 4) {
        this.customerContactValidation = false
      } else {
        this.customerContactValidation = true
      }
    },
    checkForCustomerSchedule() {
      if (this.startTime && (this.customerContact.length || this.validateEmail(this.customerEmail))) {
        let customer = ''
        if (this.validateEmail(this.customerEmail)) {
          customer = this.customerEmail
        } else {
          customer = this.customerContact
        }
        this.$http
          .post('appointment/bookings/customer-schedule-availability', {
            date: this.start, time: this.startTime, customer, booking: this.$route.params.id,
          })
          .then(response => {
            if (response.data.exists === true) {
              this.scheduleError = 'This customer has another booking in a similar timeslot.'
              this.scheduleValidation = true
            } else {
              this.scheduleValidation = false
            }
          })
          .catch(error => {
            this.scheduleError = error.data.message
            this.scheduleValidation = true
          })
      }
    },
    validateEmail(inputText) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (inputText.match(mailformat)) {
        return true
      }
      return false
    },
    setNatureOfCalls() {
      this.callNatures = []

      const log = this.callLogTypes.find(o => o._id === this.typeOfCall)
      if (log) {
        log.callNatures.forEach(element => {
          const obj = {}
          obj.title = element
          obj.code = element
          this.callNatures.push(obj)
        })

        // this.natureOfCall = this.callNatures[0].code || ''
      }
    },
    makeCheckboxToRadio(key) {
      const lastSelected = this.customFields[key].value.slice(-1)[0]
      if (lastSelected) {
        this.customFields[key].value = this.customFields[key].value.filter(code => code === lastSelected)
      }

      this.generateBookingTitle()
    },
    generateBookingTitle() {
      let title = ''
      this.activityCustomFields.forEach((arrayItem, key) => {
        if (arrayItem.displayInCalendar === 'yes' && this.customFields[key].value !== '') {
          const val = this.customFields[key].value
          if (Array.isArray(val)) {
            val.forEach(arrVal => {
              let calVal = {}
              if (arrayItem.type === 'multi-select') {
                calVal = arrayItem.extraOptions.find(o => o.key === arrVal)
              } else {
                calVal = this.requiredOptions.find(o => o.value === arrVal)
              }
              title = `${title}${arrayItem.label} ${calVal.value} `
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            if (arrayItem.extraOptions.length) {
              const calVal = arrayItem.extraOptions.find(o => o.key === val)
              if (calVal) {
                title = `${title}${arrayItem.label} ${calVal.value} `
              } else {
                title = `${title}${arrayItem.label} ${val} `
              }
            } else {
              title = `${title}${arrayItem.label} ${val} `
            }
          }
        }
      })
      this.title = title
    },
    setEventPackages() {
      this.$http.get(`appointment/bookings/respond-with/event-packages/${this.eventID}`)
        .then(response => {
          this.eventPackageOptions = response.data.packages || []
          const { event } = response.data
          if (event.timeSlotBooking === 'not available') {
            this.showTimeSelection = false
          }
          const enable = [
            {
              from: this.dateFormat(response.data.event.startDate),
              to: this.dateFormat(response.data.event.endDate),
            },
          ]
          this.flatPickrConfig.enable = enable

          this.activitySelection = 'event'
          this.setEventPackageCustomFields()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

      this.getTimeFromDB()
    },

    setEventPackageCustomFields() {
      const pack = this.eventPackageOptions.find(o => o._id === this.packageID)
      if (pack) {
        if (pack.customFields.length) {
          const newArray = []
          pack.customFields.forEach(arrayItem => {
            const newObj = {}
            newObj.key = arrayItem.label
            const exist = this.previousValues.find(o => o.key === arrayItem.label)
            if (exist) {
              if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                // newObj.value = exist.value || []
                if (Array.isArray(exist.value)) {
                  newObj.value = exist.value || []
                } else {
                  newObj.value = [exist.value] || []
                }
              } else {
                newObj.value = exist.value || ''
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                newObj.value = []
              } else {
                newObj.value = ''
              }
            }
            newArray.push(newObj)
          })

          this.customFields = newArray
        } else {
          this.customFields = []
        }

        this.activityCustomFields = pack.customFields || []
      }
    },
    setExtraFields() {
      const eventActivity = this.activityTypeOptions.find(o => o.typeName === 'Event')

      if (eventActivity._id === this.activityType) {
        this.showEventSelection = true
      } else {
        this.eventID = ''
        this.packageID = ''
        this.activitySelection = 'activity'

        const type = this.activityTypeOptions.find(o => o._id === this.activityType)
        if (type) {
          if (type.customFields.length) {
            const newArray = []
            type.customFields.forEach(arrayItem => {
              const newObj = {}
              newObj.key = arrayItem.label
              const exist = this.previousValues.find(o => o.key === arrayItem.label)
              if (exist) {
                if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                  newObj.value = exist.value || []
                } else {
                  newObj.value = exist.value || ''
                }
              } else {
                // eslint-disable-next-line no-lonely-if
                if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                  newObj.value = []
                } else {
                  newObj.value = ''
                }
              }
              newArray.push(newObj)
            })

            this.customFields = newArray
          } else {
            this.customFields = []
          }

          this.activityCustomFields = type.customFields || []
        }

        this.getTimeFromDB()
      }
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'appointment-bookings-index', params: { type: 'all-booking' } })
          }
        })
    },
    submitForm() {
      this.$refs.bookingEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('customerID', this.customerID)
          if (this.customerID) {
            formData.append('customerName', this.customerName)
          } else {
            formData.append('customerName', this.query)
          }
          if (this.agentID) {
            formData.append('agentName', this.agentName)
          } else {
            formData.append('agentName', this.agentQuery)
          }
          // formData.append('customerName', this.customerName)
          formData.append('customerContact', this.customerContact)
          formData.append('customerEmail', this.customerEmail)
          formData.append('sourceOfBooking', this.sourceOfBooking)
          formData.append('packageID', this.packageID)
          formData.append('description', this.description)
          formData.append('remarks', this.remarks)
          formData.append('paymentRemarks', this.paymentRemarks)
          formData.append('agentID', this.agentID)
          // formData.append('agentName', this.agentName)
          formData.append('agentCode', this.agentCode)
          formData.append('agentContact', this.agentContact)
          formData.append('title', this.title)
          formData.append('typeOfCall', this.typeOfCall)
          formData.append('natureOfCall', this.natureOfCall)
          formData.append('callRemarks', this.callRemarks)
          formData.append('startDate', this.start)
          formData.append('startTime', this.startTime)
          formData.append('dutyOfficer', this.dutyOfficer)
          formData.append('timeSlotDuration', this.timeSlotDuration)
          formData.append('customFields', JSON.stringify(this.customFields))

          this.$http.patch(`appointment/bookings/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$swal({
                title: 'Booking Saved',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: true,
                cancelButtonText: 'View Entry',
                allowOutsideClick: false,
                confirmButtonText: 'Return to Booking',
                customClass: {
                  confirmButton: 'btn btn-primary mr-1',
                  cancelButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    this.$router.push({ name: 'appointment-bookings-index', params: { type: 'all-booking' } })
                  } else {
                    this.$router.push({ name: 'appointment-bookings-show', params: { id: response.data.data._id } }).then(() => window.scrollTo(0, 0))
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                  } else if (validationError.param === 'startDate') {
                    this.startError = validationError.msg
                    this.startValidation = true
                  } else if (validationError.param === 'startTime') {
                    this.startTimeError = validationError.msg
                    this.startTimeValidation = true
                  } else if (validationError.param === 'activityType') {
                    this.activityTypeError = validationError.msg
                    this.activityTypeValidation = true
                  } else if (validationError.param === 'sourceOfBooking') {
                    this.sourceOfBookingError = validationError.msg
                    this.sourceOfBookingValidation = true
                  } else if (validationError.param === 'paymentRemarks') {
                    this.paymentRemarksError = validationError.msg
                    this.paymentRemarksValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'dutyOfficer') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  } else if (validationError.param === 'eventID') {
                    this.eventIDError = validationError.msg
                    this.eventIDValidation = true
                  } else if (validationError.param === 'packageID') {
                    this.packageIDError = validationError.msg
                    this.packageIDValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }
  .z-index-99{
     z-index: 99;
  }
  .result-search-header{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #333333;
  }
  .card-body .input-group .input__append-border-right{
    border-right: 1px solid #D2DCEA !important;
  }
  .card-body .input-group .input__append-border-right:focus{
    border-color: #104D9D !important;
  }
</style>
